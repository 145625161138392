// Demo conversations with more engaging content
export const demoMessages = [
    {
      text: "こんにちは！料金プランについて教えていただけますか？",
      isBot: false
    },
    {
      text: "はい、もちろんです！現在、Basic プランとPremium プランの2つをご用意しています。どちらのプランにご興味がありますか？",
      isBot: true
    },
    {
      text: "Basic プランの詳細を教えてください。",
      isBot: false
    },
    {
      text: "Basic プランは中小企業向けで、月額¥980円です。主な特徴として、最大75%のサポート費用割引、プライベートなデータ管理、月間3,000件の問い合わせ対応が可能です。日々の業務効率化にお役立ていただけます！",
      isBot: true
    },
    {
      text: "3,000件というのは十分だと思いますが、もし超過した場合はどうなりますか？",
      isBot: false
    },
    {
      text: "ご質問ありがとうございます。件数を超過した場合は、Premium プランへのアップグレードをお勧めしています。Premium プランでは月間10,000件まで対応可能です。アップグレードについて詳しくお知りになりたいですか？",
      isBot: true
    },
    {
      text: "はい、Premium プランについても詳しく教えてください。",
      isBot: false
    },
    {
      text: "Premium プランは月額¥19,800円で、AIによる効率化で運用コストを50%削減できます。ウェブサイトへの埋め込みやクエリの分析機能も搭載。より大規模な運用に適しています。また、プライベートなチャットボットの作成も可能です。",
      isBot: true
    },
    {
      text: "ウェブサイトへの埋め込みは簡単にできますか？",
      isBot: false
    },
    {
      text: "はい、とても簡単です！数行のコードを追加するだけで導入できます。また、導入サポートも提供していますので、技術的なご不安がある場合もお気軽にご相談ください。",
      isBot: true
    },
    {
      text: "データのセキュリティについて気になります。どのような対策をしていますか？",
      isBot: false
    },
    {
      text: "セキュリティ対策は万全です。両プランとも、データの暗号化、アクセス制御、定期的なセキュリティ監査を実施しています。また、プライベートなデータ管理により、お客様の情報は適切に保護されます。",
      isBot: true
    },
    {
      text: "無料トライアルはありますか？",
      isBot: false
    },
    {
      text: "はい！14日間の無料トライアルをご用意しています。期間中はBasic プランの全機能をお試しいただけます。実際に使ってみて、お客様の業務に合うかどうかご確認ください。トライアルを始めてみませんか？",
      isBot: true
    },
    {
      text: "ありがとうございます。トライアルを申し込む前に社内で相談してみます。",
      isBot: false
    },
    {
      text: "承知いたしました。ご検討ありがとうございます。社内での相談が終わりましたら、お気軽にまたご連絡ください。ご不明な点があれば、いつでもサポートさせていただきます。良い一日をお過ごしください！",
      isBot: true
    }
  ];