import React from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Container,
  Stack,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const MotionPaper = motion(Paper);
const MotionBox = motion(Box);

const SubscriptionPrompt = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigateToPricing = () => {
    navigate("/price");
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: { xs: 12, sm: 12 }, pb: { xs: 8, sm: 16 } }}
    >
      <MotionPaper
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 3,
          textAlign: "center",
          overflow: "hidden",
          position: "relative",
          background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
        }}
      >
        {/* Decorative elements */}
        <MotionBox
          sx={{
            position: "absolute",
            top: -30,
            left: -30,
            width: 200,
            height: 200,
            borderRadius: "50%",
            background: `linear-gradient(135deg, ${theme.palette.primary.light}22 0%, ${theme.palette.primary.main}11 100%)`,
          }}
          animate={{
            scale: [1, 1.05, 1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />

        <MotionBox
          sx={{
            position: "absolute",
            bottom: -40,
            right: -40,
            width: 250,
            height: 250,
            borderRadius: "50%",
            background: `linear-gradient(135deg, ${theme.palette.primary.light}11 0%, ${theme.palette.primary.main}22 100%)`,
          }}
          animate={{
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "easeInOut",
            delay: 0.5,
          }}
        />

        <Stack
          spacing={3}
          alignItems="center"
          sx={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <MotionBox
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.2,
            }}
          >
            <Box
              sx={{
                bgcolor: "primary.main",
                color: "white",
                width: 80,
                height: 80,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
                margin: "0 auto",
              }}
            >
              <LockIcon sx={{ fontSize: 40 }} />
            </Box>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              fontWeight={600}
            >
              サブスクリプションが必要です
            </Typography>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              paragraph
              sx={{ maxWidth: 600, mx: "auto" }}
            >
              この機能を利用するには有効なサブスクリプションプランが必要です。
              プランを選択して、チャットボットのすべての機能をご利用ください。
            </Typography>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            sx={{ width: "100%", maxWidth: 300, mx: "auto", mt: 2 }}
          >
            <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleNavigateToPricing}
                sx={{
                  py: 1.5,
                  borderRadius: 2,
                  fontWeight: 600,
                  textTransform: "none",
                  fontSize: "1rem",
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  boxShadow: `0 4px 14px ${theme.palette.primary.main}50`,
                }}
                endIcon={
                  <motion.div
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                  >
                    <ArrowForwardIcon />
                  </motion.div>
                }
              >
                プランを選択する
              </Button>
            </motion.div>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 4, display: "block" }}
            >
              プランを選択すると、より多くの機能を利用できるようになります。
            </Typography>
          </MotionBox>
        </Stack>
      </MotionPaper>
    </Container>
  );
};

export default React.memo(SubscriptionPrompt);
