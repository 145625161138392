import { useState, useEffect } from "react";
import { driver } from "driver.js";
import "driver.js/dist/driver.css"; // Make sure CSS is imported

const TOUR_STORAGE_KEY = "chatbotPreviewTourStatus";

export const useChatbotPreviewTourGuide = ({
  isOpen,
  deviceType,
  onDeviceTypeChange,
}) => {
  const [shouldShowTour, setShouldShowTour] = useState(false);
  const [driverInstance, setDriverInstance] = useState(null);

  // Create tour configuration with improved targeting
  const createDriverInstance = () => {
    return driver({
      showProgress: true,
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: true,
      overlayClickNext: false,
      stagePadding: 5,
      smoothScroll: true,
      popoverClass: "driver-popover-custom", // Add custom class for styling
      onHighlightStarted: () => {
        // Ensure z-index is higher than other elements
        document
          .querySelector(".driver-active-element")
          ?.style.setProperty("z-index", "10000");
      },
      showButtons: ["next", "previous", "close"],
      nextBtnText: "次へ",
      prevBtnText: "戻る",
      doneBtnText: "閉じる",
      steps: [
        {
          element: "#tour-chatwidget",
          popover: {
            title: "サンプルアニメーション",
            description: "サンプルアニメーションで動き方を順に説明します。",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#tour-desktop-preview",
          popover: {
            title: "PC画面プレビュー",
            description: "PC画面ビューへ切り替える",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#tour-mobile-preview",
          popover: {
            title: "スマートフォン画面プレビュー",
            description: "スマートフォン画面ビューへ切り替える",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });
  };

  // Check tour status from localStorage
  const checkTourStatus = () => {
    try {
      const tourStatus = localStorage.getItem(TOUR_STORAGE_KEY);
      if (!tourStatus) {
        setShouldShowTour(true);
        localStorage.setItem(
          TOUR_STORAGE_KEY,
          JSON.stringify({
            desktop: false,
            mobile: false,
          })
        );
      } else {
        const status = JSON.parse(tourStatus);
        setShouldShowTour(!status.desktop || !status.mobile);
      }
    } catch (error) {
      console.error("Error checking tour status:", error);
      setShouldShowTour(true);
    }
  };

  // Mark device type as viewed
  const markDeviceTypeAsViewed = (type) => {
    try {
      const tourStatus = JSON.parse(
        localStorage.getItem(TOUR_STORAGE_KEY) ||
          '{"desktop":false,"mobile":false}'
      );
      tourStatus[type] = true;
      localStorage.setItem(TOUR_STORAGE_KEY, JSON.stringify(tourStatus));

      if (tourStatus.desktop && tourStatus.mobile) {
        setShouldShowTour(false);
      }
    } catch (error) {
      console.error("Error marking device type as viewed:", error);
    }
  };

  // Start tour with better timing
  const startTourWithDelay = () => {
    // First destroy any existing instance
    if (driverInstance) {
      driverInstance.destroy();
      setDriverInstance(null);
    }

    const newDriverInstance = createDriverInstance();
    setDriverInstance(newDriverInstance);

    // Use RAF for smoother timing
    requestAnimationFrame(() => {
      setTimeout(() => {
        try {
          // Verify elements exist before starting tour
          const elements = [
            "#tour-chatwidget",
            "#tour-desktop-preview",
            "#tour-mobile-preview",
          ];
          const allElementsExist = elements.every((id) =>
            document.querySelector(id)
          );

          if (allElementsExist) {
            newDriverInstance.drive();
          } else {
            console.warn("Not all tour elements are present in the DOM");
          }
        } catch (error) {
          console.error("Error starting tour:", error);
        }
      }, 800); // Adjusted timing
    });

    return newDriverInstance;
  };

  // Handle device type change with improved timing
  const handleDeviceTypeChange = (newDeviceType) => {
    try {
      const tourStatus = JSON.parse(
        localStorage.getItem(TOUR_STORAGE_KEY) ||
          '{"desktop":false,"mobile":false}'
      );
      onDeviceTypeChange(newDeviceType);

      if (!tourStatus[newDeviceType]) {
        if (driverInstance) {
          driverInstance.destroy();
          setDriverInstance(null);
        }

        requestAnimationFrame(() => {
          setTimeout(() => {
            const newDriverInstance = createDriverInstance();
            setDriverInstance(newDriverInstance);
            newDriverInstance.drive();
            markDeviceTypeAsViewed(newDeviceType);
          }, 500);
        });
      }
    } catch (error) {
      console.error("Error handling device type change:", error);
      onDeviceTypeChange(newDeviceType);
    }
  };

  // Effect for modal open state
  useEffect(() => {
    if (isOpen) {
      checkTourStatus();
    }
  }, [isOpen]);

  // Effect for tour management with improved cleanup
  useEffect(() => {
    let currentDriverInstance = null;

    const initTour = async () => {
      if (isOpen && shouldShowTour) {
        // Wait for DOM to be fully ready
        await new Promise((resolve) => {
          requestAnimationFrame(() => {
            setTimeout(resolve, 500);
          });
        });

        currentDriverInstance = startTourWithDelay();
        markDeviceTypeAsViewed(deviceType);
      }
    };

    initTour();

    // Cleanup function
    return () => {
      if (currentDriverInstance) {
        currentDriverInstance.destroy();
      }
      if (driverInstance) {
        driverInstance.destroy();
        setDriverInstance(null);
      }
    };
  }, [isOpen, deviceType, shouldShowTour]);

  // CSS injection for driver.js popover
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .driver-popover {
        z-index: 100000 !important;
      }
      .driver-active-element {
        z-index: 100000 !important;
      }
      .driver-overlay {
        z-index: 99999 !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return {
    shouldShowTour,
    handleDeviceTypeChange,
  };
};
